import React from "react"
import { Link } from "gatsby"

// Render lists of link posts defined in 'nextPosts' frontmatter
const NextPosts = ({ nextPosts }) => {
  return nextPosts && nextPosts.length > 0 ? (
    <>
      <h1>
        What's next?{" "}
        <span role="img" aria-label="emoji">
          🤔
        </span>
      </h1>
      {nextPosts.map((nextPost, i) => {
        return (
          <h2>
            <Link to={nextPost.fields.slug} key={i}>
              {`- ${nextPost.frontmatter.title}`}
            </Link>
          </h2>
        )
      })}
    </>
  ) : null
}

export default NextPosts
